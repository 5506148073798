import React, { useEffect, useRef, useState } from 'react'
import io from 'socket.io-client'
import queryString from 'query-string'
import adapter from 'webrtc-adapter' // eslint-disable-line
import signal from './signal'
import tileVideo from './tileVideo'
import mockVideo from './mockVideo'
import TuningAudio from './media/Oboe.wav'

const App = () => {

  const videoRefs = useRef([])
  const chatVideoRef = useRef()
  const clickAudioRef = useRef()
  const tuningAudioRef = useRef()
  const socketRef = useRef()
  const inputStreamRef = useRef()
  const incomingConnectionRef = useRef()
  const outgoingConnectionRef = useRef()
  const hostConnectionRef = useRef()

  const [userCount, setUserCount] = useState(0)
  const [tuningEnabled, setTuningEnabled] = useState(false)
  const [chatEnabled, setChatEnabled] = useState(false)

  const urlParams = queryString.parse(window.location.search)
  const currentId = +urlParams.id

  // socket initialization
  useEffect(() => {
    const socket = io(window.config.apiServer)
    socketRef.current = socket

    socket.emit('getChatEnabled', enabled => {
      setChatEnabled(enabled)
    })
    socket.emit('getUserCount', count => {
      Array.from(Array(count).keys()).forEach(() => {
        videoRefs.current.push(React.createRef())
      })
      setUserCount(count)
    })
  }, [])

  // click initialization
  useEffect(() => {
    const onKeyDown = event => {
      if (event.key === 'ArrowUp') {
        clickAudioRef.current.volume = Math.min(clickAudioRef.current.volume + 0.1, 1)
      }
      else if (event.key === 'ArrowDown') {
        clickAudioRef.current.volume = Math.max(clickAudioRef.current.volume - 0.1, 0)
      }
    }
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [])

  // input initialization
  useEffect(() => {
    if (userCount === 0 || !currentId) { return }
    let getInputStream
    if (Object.keys(urlParams).includes('mock')) {
      getInputStream = mockVideo
    }
    else {
      getInputStream = async() => {
        try {
          return await navigator.mediaDevices.getUserMedia({
            video: {
              aspectRatio: 16 / 9,
              width: 640
            },
            audio: {
              autoGainControl: false,
              echoCancellation: false,
              noiseSuppression: false
            }
          })
        }
        catch (err) {
          console.log('Camera error: ', err)
        }
      }
    }

    getInputStream(currentId).then(stream => {
      inputStreamRef.current = stream
      stream.getVideoTracks()[0].contentHint = 'motion'
      stream.getAudioTracks()[0].contentHint = 'music'

      videoRefs.current[currentId - 1].current.srcObject = stream
      videoRefs.current[currentId - 1].current.oncanplay = () => {
        videoRefs.current[currentId - 1].current.play()
      }
      socketRef.current.emit('identify', currentId)
      socketRef.current.emit('setStreamId', currentId, stream.id)
      socketRef.current.emit('isHostConnected', hostConnected => {
        if (hostConnected) {
          buildHostConnection()
        }
      })
      socketRef.current.on('hostConnected', () => {
        buildHostConnection()
      })
      socketRef.current.on('tune', enabled => {
        setTuningEnabled(enabled)
        if (enabled) {
          tuningAudioRef.current.play()
        }
        else {
          tuningAudioRef.current.load()
        }
      })
      socketRef.current.on('chat', enabled => {
        setChatEnabled(enabled)
      })
      socketRef.current.on('gain', (id, value) => {
        videoRefs.current[id - 1].current.volume = Math.min(value, 1.0)
      })
      socketRef.current.on('disconnect', id => {
        closeConnection(incomingConnectionRef.current)
        closeConnection(outgoingConnectionRef.current)
        if (id === 'host') {
          closeConnection(hostConnectionRef.current)
        }
      })
      socketRef.current.on('connectIncoming', id => {
        if (id === currentId) {
          buildIncomingConnection()
        }
      })
      socketRef.current.on('bandwidth', bandwidth => {
        if (outgoingConnectionRef.current) {
          outgoingConnectionRef.current.getSenders().forEach(sender => {
            if (sender.track.kind === 'video' && sender.track.id === inputStreamRef.current.getVideoTracks()[0].id) {
              const parameters = sender.getParameters()
              if (parameters.encodings.length > 0) {
                if (bandwidth) {
                  parameters.encodings[0].maxBitrate = bandwidth
                  parameters.encodings[0].degradationPerformance = 'maintain-framerate'
                }
                else {
                  delete parameters.encodings[0].maxBitrate
                }
                sender.setParameters(parameters)
              }
            }
          })
        }
      })
    })
  }, [userCount]) // eslint-disable-line

  // incoming stats
  useEffect(() => {
    const oldSamples = {}
    socketRef.current.emit('getBufferParams', bufferParams => {
      setInterval(() => {
        let resolved = true
        if (!incomingConnectionRef.current) { return }
        incomingConnectionRef.current.getStats().then(stats => {
          const timestamps = []
          stats.forEach(stat => {
            if (stat.type === 'track' && stat.kind === 'audio') {
              if (!oldSamples[stat.id] || oldSamples[stat.id].inserted !== stat.insertedSamplesForDeceleration || oldSamples[stat.id].removed !== stat.removedSamplesForAcceleration) {
                resolved = false
              }
              oldSamples[stat.id] = { inserted: stat.insertedSamplesForDeceleration, removed: stat.removedSamplesForAcceleration }
            }
            else if (stat.type === 'inbound-rtp') {
              if (stat.estimatedPlayoutTimestamp) {
                timestamps.push(stat.estimatedPlayoutTimestamp)
              }
            }
          })
          const max = timestamps.reduce((a, b) => Math.max(a, b), 0)
          const min = timestamps.reduce((a, b) => Math.min(a, b), Number.MAX_VALUE)
          const offset = Math.max(max - min, 0) - (currentId === 1 ? 0 : bufferParams.offset * 1000)
          socketRef.current.emit('stats', currentId, { resolved: resolved, offset: offset })
        })
      }, 2000)
    })
  }, [currentId])

  const buildIncomingConnection = () => {
    closeConnection(incomingConnectionRef.current)
    const connection = new RTCPeerConnection({ iceServers: [{ urls: window.config.stunServer }] })
    incomingConnectionRef.current = connection
    signal({
      connection: connection,
      socket: socketRef.current,
      sourceId: currentId,
      targetId: currentId === 1 ? 'host' : currentId - 1,
      polite: true,
      isHost: false
    })

    let trackCount = 0
    connection.ontrack = ({ track, streams }) => {
      console.log(`Adding ${track.kind} track from stream ${streams[0].id}`)
      if (window.config.debug) { console.log('ontrack', track, streams) }

      socketRef.current.emit('getStreamId', streams[0].id, streamIndex => {
        if (streamIndex === 'host') {
          clickAudioRef.current.srcObject = streams[0]
          clickAudioRef.current.oncanplay = () => {
            clickAudioRef.current.play()
            clickAudioRef.current.muted = chatEnabled
          }
        }
        else if (streamIndex) {
          videoRefs.current[streamIndex - 1].current.srcObject = streams[0]
          videoRefs.current[streamIndex - 1].current.oncanplay = () => {
            videoRefs.current[streamIndex - 1].current.play()
            videoRefs.current[streamIndex - 1].current.muted = chatEnabled || streamIndex === currentId
          }
          socketRef.current.emit('getGain', streamIndex, value => {
            videoRefs.current[streamIndex - 1].current.volume = Math.min(value, 1.0)
          })
        }

        socketRef.current.emit('getBufferParams', bufferParams => {
          const currentReceiver = connection.getReceivers().find(receiver => receiver.track.id === track.id)
          if (currentReceiver) {
            currentReceiver.playoutDelayHint = +bufferParams.size - (streamIndex === currentId - 1 ? +bufferParams.offset : 0)
          }
        })
      })

      trackCount++
      if (trackCount === 2 * currentId - 1) {
        buildOutgoingConnection()
      }
    }
  }

  const buildOutgoingConnection = () => {
    closeConnection(outgoingConnectionRef.current)
    const connection = new RTCPeerConnection({ iceServers: [{ urls: window.config.stunServer }] })
    outgoingConnectionRef.current = connection
    connection.addTrack(inputStreamRef.current.getVideoTracks()[0], inputStreamRef.current)
    connection.addTrack(inputStreamRef.current.getAudioTracks()[0], inputStreamRef.current)

    incomingConnectionRef.current.getRemoteStreams().forEach(stream => {
      stream.getTracks().forEach(track => {
        connection.addTrack(track, stream)
      })
    })

    constrainConnection(connection)
    signal({
      connection: connection,
      socket: socketRef.current,
      sourceId: currentId,
      targetId: currentId === userCount ? 'host' : currentId + 1,
      polite: false,
      isHost: false
    })
    socketRef.current.emit('connectIncoming', currentId === userCount ? 'host' : currentId + 1)
  }

  const buildHostConnection = () => {
    closeConnection(hostConnectionRef.current)
    const connection = new RTCPeerConnection({ iceServers: [{ urls: window.config.stunServer }] })
    hostConnectionRef.current = connection
    connection.addTrack(inputStreamRef.current.getAudioTracks()[0], inputStreamRef.current)
    constrainConnection(connection)

    signal({
      connection: connection,
      socket: socketRef.current,
      sourceId: currentId,
      targetId: 'host',
      polite: true,
      isHost: true
    })
    socketRef.current.emit('connectToHost', currentId)

    connection.ontrack = ({ track, streams }) => {
      console.log(`Adding ${track.kind} track from host`)
      if (window.config.debug) { console.log('ontrack', track, streams) }
      chatVideoRef.current.srcObject = streams[0]
      chatVideoRef.current.oncanplay = () => {
        chatVideoRef.current.play()
      }
    }
  }

  const closeConnection = connection => {
    if (connection) {
      connection.close()
      connection = null
    }
  }

  const constrainConnection = connection => {
    // limit audio to only the opus codec
    const audioCapabilities = RTCRtpSender.getCapabilities('audio')
    const opusCodec = audioCapabilities.codecs.find(codec => codec.mimeType === 'audio/opus')
    connection.getTransceivers().forEach(transceiver => {
      if (transceiver.sender.track.kind === 'audio') {
        transceiver.setCodecPreferences([opusCodec])
      }
    })
  }

  return (
    <>
      {Array.from(Array(userCount).keys()).map(index => {
        const videoParams = tileVideo(index, userCount)
        return (
          <video
            key={index}
            width={videoParams.width}
            height={videoParams.height}
            ref={videoRefs.current[index]}
            muted={chatEnabled || index === currentId - 1}
            style={{ position: 'absolute', left: videoParams.x, top: videoParams.y }}
          />
        )
      })}
      <video ref={chatVideoRef} width={320} height={180} style={{ position: 'absolute', left: 0, top: 0, display: chatEnabled ? 'inherit' : 'none' }} muted={!chatEnabled} />
      <audio ref={clickAudioRef} muted={chatEnabled} />
      <audio ref={tuningAudioRef} muted={!tuningEnabled}>
        <source src={TuningAudio} type='audio/wav' />
      </audio>
    </>
  )
}

export default App
