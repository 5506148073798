import React from 'react'
import ReactDOM from 'react-dom'
import queryString from 'query-string'
import './index.css'
import App from './App'
import HostApp from './HostApp'

const urlParams = queryString.parse(window.location.search)

ReactDOM.render(urlParams.id === 'host' ? <HostApp /> : <App />, document.getElementById('root'))

const canvas = { width: 1280, height: 720 }
const root = document.getElementById('root')
root.style.width = `${canvas.width}px`
root.style.height = `${canvas.height}px`
scaleToFit(false)

function scaleToFit(doFill) {
  if (urlParams.id === 'host') { return }
  const vScale = window.innerHeight / canvas.height
  const hScale = window.innerWidth / canvas.width
  const scale = doFill ? Math.max(vScale, hScale) : Math.min(vScale, hScale)
  root.style.transform = `translate(${ -canvas.width / 2 }px, ${ -canvas.height / 2 }px) scale(${ scale }, ${ scale }) translate(${ canvas.width / 2 }px, ${ canvas.height / 2 }px)`
  //root.style.overflow = 'hidden'
}

window.addEventListener('resize', () => scaleToFit(false), false)
