const mockVideo = async(id) => {
  const source = []
  source.push(require('./media/Mock Wide 1.mp4'))
  source.push(require('./media/Mock Wide 2.mp4'))
  source.push(require('./media/Mock Wide 3.mp4'))

  const videoElement = document.createElement('video')
  videoElement.width = 640
  videoElement.height = 360
  videoElement.loop = true
  videoElement.src = source[id >= 1 && id <= 3 ? id - 1 : 0]
  videoElement.muted = true

  await videoElement.play()

  document.body.addEventListener('click', () => {
    videoElement.muted = false
  })

  const videoStream = videoElement.captureStream ? videoElement.captureStream() : videoElement.mozCaptureStream()
  return videoStream
}

export default mockVideo
