const signal = ({ connection, socket, sourceId, targetId, polite, isHost }) => {
  let makingOffer = false
  let ignoreOffer = false
  const messageName = isHost ? 'hostSignal' : 'signal'

  connection.onicecandidate = ({ candidate }) => {
    if (window.config.debug) { console.log('onicecandidate', candidate) }
    socket.emit(messageName, { id: sourceId, target: targetId, candidate })
  }

  connection.onnegotiationneeded = async() => {
    if (window.config.debug) { console.log('onnegotiationneeded') }
    try {
      makingOffer = true
      await connection.setLocalDescription()
      socket.emit(messageName, { id: sourceId, target: targetId, description: connection.localDescription })
    }
    catch (err) {
      console.error(err)
    }
    finally {
      makingOffer = false
    }
  }

  socket.on(messageName, data => {
    if (connection.connectionState !== 'closed' && data.target === sourceId && data.id === targetId) {
      readData(data)
    }
  })

  const readData = async message => {
    const { description, candidate } = message
    try {
      if (description) {
        const offerCollision = description.type === 'offer' && (makingOffer || connection.signalingState !== 'stable')
        ignoreOffer = !polite && offerCollision
        if (ignoreOffer) { return }
        await connection.setRemoteDescription(description)
        if (description.type === 'offer') {
          await connection.setLocalDescription()
          socket.emit(messageName, { id: sourceId, target: targetId, description: connection.localDescription })
        }
      }
      else if (candidate) {
        try {
          await connection.addIceCandidate(candidate)
        }
        catch (err) {
          if (!ignoreOffer) { throw err }
        }
      }
    }
    catch (err) {
      console.error(err)
    }
  }
}

export default signal
