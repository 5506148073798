import React, { useEffect, useRef, useState } from 'react'
import io from 'socket.io-client'
import queryString from 'query-string'
import adapter from 'webrtc-adapter' // eslint-disable-line
import signal from './signal'
import tileVideo from './tileVideo'

const HostApp = () => {

  const urlParams = queryString.parse(window.location.search)
  const userCount = +urlParams.users || 2
  const bufferSize = +urlParams.bufferSize || 2.95
  const bufferOffset = +urlParams.bufferOffset || 0.06666

  const canvasRef = useRef()
  const videoRefs = useRef([])
  const socketRef = useRef()

  const outputAudioElementRef = useRef()
  const outputAudioContextRef = useRef()
  const outputAudioGainRefs = useRef([])
  const outputAudioPannerRefs = useRef([])
  const outputAudioDestinationRef = useRef()

  const chatAudioElementRef = useRef()
  const chatVideoElementRef = useRef()
  const chatContextRef = useRef()
  const chatSourceRefs = useRef([])
  const chatDestinationRefs = useRef([])
  const chatHostSourceRef = useRef()
  const chatHostDestinationRef = useRef()

  const clickContextRef = useRef()
  const clickSourceRef = useRef()
  const clickSourceBufferRef = useRef()
  const clickDestinationRef = useRef()

  const inputStreamRef = useRef()
  const incomingConnectionRef = useRef()
  const outgoingConnectionRef = useRef()
  const hostConnectionRef = useRef({})

  const dryGainNodeRef = useRef()
  const wetGainNodeRef = useRef()
  const masterGainNodeRef = useRef()
  const mediaRecorderRef = useRef()
  const mediaStreamerRef = useRef()

  const [chatEnabled, setChatEnabled] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [isStreaming, setIsStreaming] = useState(false)
  const [isClickTrackPlaying, setIsClickTrackPlaying] = useState(false)
  const [isClickTrackLoaded, setIsClickTrackLoaded] = useState(false)
  const [tuningEnabled, setTuningEnabled] = useState(false)
  const [reverbStrength, setReverbStrength] = useState(0)
  const [gainLevels, setGainLevels] = useState(Array(userCount + 2).fill(1)) // extra counts for master gain and click
  const [panLevels, setPanLevels] = useState(Array(userCount + 2).fill(0))
  const [isOnline, setIsOnline] = useState(Array(userCount).fill(false))
  const [avgPower, setAvgPower] = useState(Array(userCount + 2).fill(-100)) // extra counts for master avg power and click
  const [delayResolved, setDelayResolved] = useState(Array(userCount + 1).fill(false))
  const [syncOffset, setSyncOffset] = useState(Array(userCount + 1).fill(0))
  const [bandwidth, setBandwidth] = useState('unlimited')
  const [outputAudioChanged, setOutputAudioChanged] = useState(false) // hack to set gain and pan levels on startup

  // socket initialization
  useEffect(() => {
    const socket = io(window.config.apiServer)
    socketRef.current = socket
    socket.emit('setUserCount', userCount)
    socket.emit('setBufferSize', bufferSize)
    socket.emit('setBufferOffset', bufferOffset)
    socket.emit('getChatEnabled', enabled => {
      setChatEnabled(enabled)
    })
    socket.on('chat', enabled => {
      setChatEnabled(enabled)
    })
    Array.from(Array(userCount).keys()).forEach(index => {
      socket.emit('getGain', index + 1, value => {
        setGainLevels(oldLevels => {
          const newLevels = [...oldLevels]
          newLevels[index] = value
          return newLevels
        })
      })
      socket.emit('getPan', index + 1, value => {
        setPanLevels(oldLevels => {
          const newLevels = [...oldLevels]
          newLevels[index] = value
          return newLevels
        })
      })
    })
    socket.on('disconnect', id => {
      closeConnection(incomingConnectionRef.current)
      closeConnection(outgoingConnectionRef.current)

      if (hostConnectionRef.current[id] && hostConnectionRef.current[id].current) {
        hostConnectionRef.current[id].current.close()
        delete hostConnectionRef.current[id]
        buildChatAudio()
        setIsOnline(oldIsOnline => {
          const newIsOnline = [...oldIsOnline]
          newIsOnline[id - 1] = false
          return newIsOnline
        })
      }
    })

    socket.on('connectToHost', id => {
      buildHostConnection(id)
    })
    socket.on('connectIncoming', id => {
      if (id === 'host') {
        buildIncomingConnection()
      }
    })
    socket.on('stats', (id, stats) => {
      setDelayResolved(oldDelayResolved => {
        const newDelayResolved = [...oldDelayResolved]
        newDelayResolved[id - 1] = stats.resolved
        return newDelayResolved
      })
      setSyncOffset(oldSyncOffset => {
        const newSyncOffset = [...oldSyncOffset]
        newSyncOffset[id - 1] = stats.offset
        return newSyncOffset
      })
    })
  }, []) // eslint-disable-line

  // input initialization
  useEffect(() => {
    const getInputStream = async() => {
      try {
        return await navigator.mediaDevices.getUserMedia({
          video: {
            aspectRatio: 16 / 9,
            width: 320
          },
          audio: {
            autoGainControl: true,
            echoCancellation: false,
            noiseSuppression: true
          }
        })
      }
      catch (err) {
        console.log('Camera error: ', err)
      }
    }

    getInputStream().then(stream => {
      inputStreamRef.current = stream
      stream.getVideoTracks()[0].contentHint = 'motion'
      stream.getAudioTracks()[0].contentHint = 'speech'
      socketRef.current.emit('identify', 'host')
      chatHostSourceRef.current = chatContextRef.current.createMediaStreamSource(inputStreamRef.current)
      chatVideoElementRef.current.srcObject = stream
      chatVideoElementRef.current.oncanplay = () => {
        chatVideoElementRef.current.play()
      }
      buildChatAudio()
    })
  }, [])

  // output initialization
  useEffect(() => {
    if (userCount === 0) { return }
    chatContextRef.current = new AudioContext()
    chatHostDestinationRef.current = chatContextRef.current.createMediaStreamDestination()
    chatAudioElementRef.current.srcObject = chatHostDestinationRef.current.stream
    chatAudioElementRef.current.oncanplay = () => {
      chatAudioElementRef.current.play()
    }

    clickContextRef.current = new AudioContext()
    clickDestinationRef.current = clickContextRef.current.createMediaStreamDestination()
    clickDestinationRef.current.stream.getAudioTracks()[0].contentHint = 'music'

    // the click track goes to sleep and loses sync without a steady supply of audio samples.
    // this subsonic sine wave keeps the party going. every night.
    const oscNode = new OscillatorNode(clickContextRef.current, { frequency: 20, type: 'sine' })
    const gainNode = clickContextRef.current.createGain()
    gainNode.gain.value = 0.01
    oscNode.connect(gainNode)
    gainNode.connect(clickDestinationRef.current)
    oscNode.start()
    socketRef.current.emit('setStreamId', 'host', clickDestinationRef.current.stream.id)

    const videoParams = []
    Array.from(Array(userCount).keys()).forEach(index => {
      videoRefs.current.push(React.createRef())
      const remoteVideo = document.createElement('video')
      remoteVideo.width = 640
      remoteVideo.height = 360
      remoteVideo.muted = true
      videoRefs.current[index].current = remoteVideo
      videoParams.push(tileVideo(index, userCount))
      outputAudioGainRefs.current.push(React.createRef())
      outputAudioPannerRefs.current.push(React.createRef())
      chatSourceRefs.current.push(React.createRef())
      chatDestinationRefs.current.push(React.createRef())
      chatDestinationRefs.current[index].current = chatContextRef.current.createMediaStreamDestination()
    })
    // extras for click track
    outputAudioGainRefs.current.push(React.createRef())
    outputAudioPannerRefs.current.push(React.createRef())
    outputAudioGainRefs.current.push(React.createRef())
    outputAudioPannerRefs.current.push(React.createRef())

    const ctx = canvasRef.current.getContext('2d', { desynchronized: false, alpha: false })
    const drawScreen = () => {
      Array.from(Array(userCount).keys()).forEach(index => {
        ctx.drawImage(videoRefs.current[index].current, videoParams[index].x, videoParams[index].y, videoParams[index].width, videoParams[index].height)
      })
      requestAnimationFrame(drawScreen)
    }
    drawScreen()
  }, [userCount])

  useEffect(() => {
    const everyoneOnline = isOnline.reduce((total, current) => total && current, true)
    if (everyoneOnline) {
      buildOutgoingConnection()
    }
  }, [isOnline]) // eslint-disable-line

  // hack to set pan and gain levels at startup
  useEffect(() => {
    outputAudioGainRefs.current.forEach(({ current }, index) => {
      if (current) { current.gain.value = gainLevels[index] }
    })
    outputAudioPannerRefs.current.forEach(({ current }, index) => {
      if (current) { current.pan.value = panLevels[index] }
    })
  }, [outputAudioChanged]) // eslint-disable-line

  // incoming stats
  useEffect(() => {
    const oldSamples = {}
    setInterval(() => {
      let resolved = true
      if (!incomingConnectionRef.current) { return }
      incomingConnectionRef.current.getStats().then(stats => {
        const timestamps = []
        stats.forEach(stat => {
          if (stat.type === 'track' && stat.kind === 'audio') {
            if (!oldSamples[stat.id] || oldSamples[stat.id].inserted !== stat.insertedSamplesForDeceleration || oldSamples[stat.id].removed !== stat.removedSamplesForAcceleration) {
              resolved = false
            }
            oldSamples[stat.id] = { inserted: stat.insertedSamplesForDeceleration, removed: stat.removedSamplesForAcceleration }
          }
          else if (stat.type === 'inbound-rtp') {
            if (stat.estimatedPlayoutTimestamp) {
              timestamps.push(stat.estimatedPlayoutTimestamp)
            }
          }
        })
        setDelayResolved(oldDelayResolved => {
          const newDelayResolved = [...oldDelayResolved]
          newDelayResolved[userCount] = resolved
          return newDelayResolved
        })
        const max = timestamps.reduce((a, b) => Math.max(a, b), 0)
        const min = timestamps.reduce((a, b) => Math.min(a, b), Number.MAX_VALUE)
        setSyncOffset(oldSyncOffset => {
          const newSyncOffset = [...oldSyncOffset]
          newSyncOffset[userCount] = Math.max(max - min, 0) - bufferOffset * 1000
          return newSyncOffset
        })
      })
    }, 2000)
  }, [bufferOffset, userCount])

  const buildIncomingConnection = () => {
    closeConnection(incomingConnectionRef.current)
    const connection = new RTCPeerConnection({ iceServers: [{ urls: window.config.stunServer }] })
    incomingConnectionRef.current = connection
    signal({
      connection: connection,
      socket: socketRef.current,
      sourceId: 'host',
      targetId: userCount,
      polite: true,
      isHost: false
    })

    let trackCount = 0
    connection.ontrack = ({ track, streams }) => {
      console.log(`Adding ${track.kind} track from stream ${streams[0].id}`)
      if (window.config.debug) { console.log('ontrack', track, streams) }

      socketRef.current.emit('getStreamId', streams[0].id, streamIndex => {
        if (streamIndex && streamIndex !== 'host') {
          videoRefs.current[streamIndex - 1].current.srcObject = streams[0]
          videoRefs.current[streamIndex - 1].current.oncanplay = () => {
            videoRefs.current[streamIndex - 1].current.play()
            videoRefs.current[streamIndex - 1].current.muted = true
          }
        }
        const currentReceiver = connection.getReceivers().find(receiver => receiver.track.id === track.id)
        if (currentReceiver) {
          currentReceiver.playoutDelayHint = +bufferSize - (streamIndex === userCount ? +bufferOffset : 0)
        }
      })

      trackCount++
      if (trackCount === userCount * 2 + 1) {
        buildOutputAudio()
      }
    }
  }

  const buildOutgoingConnection = () => {
    closeConnection(outgoingConnectionRef.current)
    const connection = new RTCPeerConnection({ iceServers: [{ urls: window.config.stunServer }] })
    outgoingConnectionRef.current = connection
    connection.addTrack(clickDestinationRef.current.stream.getAudioTracks()[0], clickDestinationRef.current.stream)
    constrainConnection(connection)

    signal({
      connection: connection,
      socket: socketRef.current,
      sourceId: 'host',
      targetId: 1,
      polite: false,
      isHost: false
    })
    socketRef.current.emit('connectIncoming', 1)
  }

  const buildHostConnection = id => {
    console.log(`Building host connection ${id}`)
    if (hostConnectionRef.current[id] && hostConnectionRef.current[id].current) {
      hostConnectionRef.current[id].current.close()
      delete hostConnectionRef.current[id]
    }
    const connection = new RTCPeerConnection({ iceServers: [{ urls: window.config.stunServer }] })
    hostConnectionRef.current[id] = React.createRef()
    hostConnectionRef.current[id].current = connection
    connection.addTrack(inputStreamRef.current.getVideoTracks()[0], inputStreamRef.current)
    connection.addTrack(chatDestinationRefs.current[id - 1].current.stream.getAudioTracks()[0], inputStreamRef.current)
    constrainConnection(connection)

    signal({
      connection: connection,
      socket: socketRef.current,
      sourceId: 'host',
      targetId: id,
      polite: false,
      isHost: true
    })
    setIsOnline(oldIsOnline => {
      const newIsOnline = [...oldIsOnline]
      newIsOnline[id - 1] = true
      return newIsOnline
    })

    connection.ontrack = ({ track, streams }) => {
      if (window.config.debug) { console.log('ontrack', track, streams) }
      if (track.kind === 'audio') {
        console.log(`Adding host audio track from stream ${streams[0].id}`)
        chatSourceRefs.current[id - 1].current = chatContextRef.current.createMediaStreamSource(streams[0])
        buildChatAudio()
        // this is a hack to work around a Chrome bug where the chat context doesn't receive audio
        // on the host end unless everyone is connected.
        // See https://stackoverflow.com/questions/54761430/playing-mediastream-using-audiocontext-createmediastreamsource-vs-htmlaudioeleme
        new Audio().srcObject = streams[0]
      }
    }
  }

  const closeConnection = connection => {
    if (connection) {
      connection.close()
      connection = null
    }
  }

  const constrainConnection = connection => {
    // limit audio to only the opus codec
    const audioCapabilities = RTCRtpSender.getCapabilities('audio')
    const opusCodec = audioCapabilities.codecs.find(codec => codec.mimeType === 'audio/opus')
    connection.getTransceivers().forEach(transceiver => {
      if (transceiver.sender.track.kind === 'audio') {
        transceiver.setCodecPreferences([opusCodec])
      }
    })
  }

  const buildOutputAudio = () => {
    if (outputAudioContextRef.current) { outputAudioContextRef.current.close() }
    const context = new AudioContext()
    outputAudioContextRef.current = context
    outputAudioDestinationRef.current = context.createMediaStreamDestination()
    outputAudioElementRef.current.srcObject = outputAudioDestinationRef.current.stream
    outputAudioElementRef.current.oncanplay = () => {
      outputAudioElementRef.current.play()
    }

    dryGainNodeRef.current = context.createGain()
    dryGainNodeRef.current.gain.value = 1 - reverbStrength
    wetGainNodeRef.current = context.createGain()
    wetGainNodeRef.current.gain.value = reverbStrength
    masterGainNodeRef.current = context.createGain()
    masterGainNodeRef.current.gain.value = gainLevels[userCount]

    const createConvolver = async() => {
      const convolver = context.createConvolver()
      const response = await fetch(require('./impulses/small.wav'))
      const arrayBuffer = await response.arrayBuffer()
      convolver.buffer = await context.decodeAudioData(arrayBuffer)
      return convolver
    }
    createConvolver().then(convolver => {
      dryGainNodeRef.current.connect(masterGainNodeRef.current)
      wetGainNodeRef.current.connect(convolver)
      convolver.connect(masterGainNodeRef.current)
      const delay = context.createDelay()
      delay.delayTime.value = 0.16666 // compensate for the video lag introduced by canvas streaming
      masterGainNodeRef.current.connect(delay)
      delay.connect(outputAudioDestinationRef.current)
      buildAnalyserNode(context, masterGainNodeRef.current, userCount)

      incomingConnectionRef.current.getRemoteStreams().forEach((stream, index) => {
        socketRef.current.emit('getStreamId', stream.id, streamIndex => {
          if (streamIndex) {
            const localIndex = streamIndex === 'host' ? userCount + 1 : streamIndex - 1
            const source = context.createMediaStreamSource(stream)
            const gain = context.createGain()
            outputAudioGainRefs.current[localIndex].current = gain
            gain.gain.value = gainLevels[localIndex]
            source.connect(gain)
            const merger = context.createChannelMerger(2)
            gain.connect(merger, 0, 0)
            gain.connect(merger, 0, 1)
            const panner = context.createStereoPanner()
            outputAudioPannerRefs.current[localIndex].current = panner
            panner.pan.value = panLevels[localIndex]
            merger.connect(panner)
            panner.connect(dryGainNodeRef.current)
            panner.connect(wetGainNodeRef.current)
            buildAnalyserNode(context, gain, localIndex)
          }
          // hack to set pans and gains on startup
          if (index === incomingConnectionRef.current.getRemoteStreams().length - 1) {
            setOutputAudioChanged(oldVal => !oldVal)
          }
        })
      })
    })
  }

  const buildAnalyserNode = (context, source, index) => {
    const analyserNode = context.createAnalyser()
    analyserNode.fftSize = 2048
    analyserNode.minDecibels = -90
    analyserNode.maxDecibels = -10
    const sampleBuffer = new Float32Array(analyserNode.fftSize)
    source.connect(analyserNode)

    let oldTimeStamp = 0
    const loopAnalyser = timeStamp => {
      if (timeStamp - oldTimeStamp > 100) {
        analyserNode.getFloatTimeDomainData(sampleBuffer)
        const bufferAvg = sampleBuffer.reduce((total, cur) => total + cur ** 2, 0) / sampleBuffer.length
        setAvgPower(oldAvgPower => {
          const newAvgPower = [...oldAvgPower]
          newAvgPower[index] = Math.max(10 * Math.log10(bufferAvg), -100)
          return newAvgPower
        })
        oldTimeStamp = timeStamp
      }
      if (context.state === 'running') {
        requestAnimationFrame(loopAnalyser)
      }
    }
    loopAnalyser()
  }

  const buildChatAudio = () => {
    chatHostSourceRef.current.disconnect()
    Object.keys(hostConnectionRef.current).forEach(outerKey => {
      chatHostSourceRef.current.connect(chatDestinationRefs.current[outerKey - 1].current)
      const source = chatSourceRefs.current[outerKey - 1].current
      if (source) {
        source.disconnect()
        source.connect(chatHostDestinationRef.current)
        Object.keys(hostConnectionRef.current).forEach(innerKey => {
          if (innerKey !== outerKey) {
            source.connect(chatDestinationRefs.current[innerKey - 1].current)
          }
        })
      }
    })
  }

  const createRecorder = () => {
    if (!outputAudioDestinationRef.current) { return }
    const mediaChunks = []
    const canvasStream = canvasRef.current.captureStream(30)
    canvasStream.addTrack(outputAudioDestinationRef.current.stream.getAudioTracks()[0], canvasStream)

    mediaRecorderRef.current = new MediaRecorder(canvasStream, {
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 2500000,
      mimeType: 'video/webm;codecs=h264'
    })
    mediaRecorderRef.current.ondataavailable = e => {
      if (e.data.size > 0) {
        mediaChunks.push(e.data)
      }
    }
    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(mediaChunks, { type: 'video/webm' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = url
      a.download = `test_${Math.random().toString(36).substr(2, 10)}.webm`
      a.click()
      window.URL.revokeObjectURL(url)
    }
    mediaRecorderRef.current.start()
  }

  const createStreamer = () => {
    if (!outputAudioDestinationRef.current) { return }
    const canvasStream = canvasRef.current.captureStream(30)
    canvasStream.addTrack(outputAudioDestinationRef.current.stream.getAudioTracks()[0], canvasStream)

    mediaStreamerRef.current = new MediaRecorder(canvasStream, {
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 2500000,
      mimeType: 'video/webm;codecs=h264'
    })
    mediaStreamerRef.current.ondataavailable = e => {
      if (e.data.size > 0) {
        socketRef.current.emit('stream', e.data)
      }
    }
    socketRef.current.emit('startStreaming')
    mediaStreamerRef.current.start(1000)
  }

  const onGainMouseUp = (index, value) => {
    socketRef.current.emit('setGain', index + 1, value)
  }

  const onPanMouseUp = (index, value) => {
    socketRef.current.emit('setPan', index + 1, value)
  }

  const onGainChange = (index, value) => {
    if (outputAudioGainRefs.current[index] && outputAudioGainRefs.current[index].current) {
      outputAudioGainRefs.current[index].current.gain.value = value
    }
    setGainLevels(oldLevels => {
      const newLevels = [...oldLevels]
      newLevels[index] = value
      return newLevels
    })
  }

  const onPanChange = (index, value) => {
    if (outputAudioPannerRefs.current[index] && outputAudioPannerRefs.current[index].current) {
      outputAudioPannerRefs.current[index].current.pan.value = value
    }
    setPanLevels(oldLevels => {
      const newLevels = [...oldLevels]
      newLevels[index] = value
      return newLevels
    })
  }

  const onMasterGainChange = event => {
    const value = +event.target.value
    if (masterGainNodeRef.current) {
      masterGainNodeRef.current.gain.value = value
    }
    setGainLevels(oldLevels => {
      const newLevels = [...oldLevels]
      newLevels[userCount] = value
      return newLevels
    })
  }

  const onClickGainChange = event => {
    const value = +event.target.value
    if (outputAudioGainRefs.current[userCount + 1] && outputAudioGainRefs.current[userCount + 1].current) {
      outputAudioGainRefs.current[userCount + 1].current.gain.value = value
    }
    setGainLevels(oldLevels => {
      const newLevels = [...oldLevels]
      newLevels[userCount + 1] = value
      return newLevels
    })
  }

  const onClickPanChange = event => {
    const value = +event.target.value
    if (outputAudioPannerRefs.current[userCount + 1] && outputAudioPannerRefs.current[userCount + 1].current) {
      outputAudioPannerRefs.current[userCount + 1].current.pan.value = value
    }
    setPanLevels(oldLevels => {
      const newLevels = [...oldLevels]
      newLevels[userCount + 1] = value
      return newLevels
    })
  }

  const onRecordClick = () => {
    if (isRecording) {
      mediaRecorderRef.current.stop()
    }
    else {
      createRecorder()
    }
    setIsRecording(oldVal => !oldVal)
  }

  const onStreamClick = () => {
    if (isStreaming) {
      socketRef.current.emit('stopStreaming')
      mediaStreamerRef.current.stop()
    }
    else {
      createStreamer()
    }
    setIsStreaming(oldVal => !oldVal)
  }

  const onChatEnabledChange = () => {
    socketRef.current.emit('setChatEnabled', !chatEnabled)
    setChatEnabled(oldVal => !oldVal)
  }

  const onTuningEnabledChange = () => {
    socketRef.current.emit('tune', !tuningEnabled)
    setTuningEnabled(oldVal => !oldVal)
  }

  const onSetReverbStrength = event => {
    setReverbStrength(+event.target.value)
    if (dryGainNodeRef.current) { dryGainNodeRef.current.gain.value = 1 - +event.target.value }
    if (wetGainNodeRef.current) { wetGainNodeRef.current.gain.value = +event.target.value }
  }

  const onClickTrackFileChange = event => {
    if (isClickTrackLoaded && isClickTrackPlaying && clickSourceRef.current) { clickSourceRef.current.stop() }
    setIsClickTrackLoaded(false)
    setIsClickTrackPlaying(false)
    const currentFile = event.target.files[0]
    if (!currentFile) { return }
    const reader = new FileReader()
    reader.readAsArrayBuffer(currentFile)
    reader.onload = readerEvent => {
      clickContextRef.current.decodeAudioData(readerEvent.target.result).then(buffer => {
        clickSourceBufferRef.current = buffer
        setIsClickTrackLoaded(true)
      })
    }
  }

  const onPlayClickTrack = () => {
    if (isClickTrackPlaying) {
      if (clickSourceRef.current) {
        clickSourceRef.current.stop()
        clickSourceRef.current.disconnect()
        clickSourceRef.current = undefined
      }
    }
    else if (clickSourceBufferRef.current) {
      clickSourceRef.current = clickContextRef.current.createBufferSource()
      clickSourceRef.current.buffer = clickSourceBufferRef.current
      clickSourceRef.current.connect(clickDestinationRef.current)
      clickSourceRef.current.loop = false
      clickSourceRef.current.start(0)
    }
    setIsClickTrackPlaying(oldVal => !oldVal)
  }

  const onSetBandwidth = event => {
    const value = event.target.value === 'unlimited' ? null : +event.target.value * 1000
    setBandwidth(event.target.value)
    socketRef.current.emit('bandwidth', value)
    Object.keys(hostConnectionRef.current).forEach(key => {
      hostConnectionRef.current[key].current.getSenders().forEach(sender => {
        if (sender.track.kind === 'video') {
          const parameters = sender.getParameters()
          if (parameters.encodings.length > 0) {
            if (value) {
              parameters.encodings[0].maxBitrate = value
            }
            else {
              delete parameters.encodings[0].maxBitrate
            }
            sender.setParameters(parameters)
          }
        }
      })
    })
  }

  return (
    <>
      <canvas width={1280} height={720} ref={canvasRef} />
      <video ref={chatVideoElementRef} width={320} height={180} style={{ position: 'absolute', left: 0, top: 0, display: chatEnabled ? 'inherit' : 'none' }} muted={true} />
      <audio ref={outputAudioElementRef} muted={chatEnabled} />
      <audio ref={chatAudioElementRef} muted={!chatEnabled} />
      <div style={{ marginLeft: '20px' }}>
        <table>
          <thead>
            <tr>
              <th style={{ width: '20px' }} />
              <th style={{ width: '25px' }} />
              <th>Gain</th>
              <th style={{ width: '50px' }} />
              <th>Pan</th>
              <th style={{ width: '50px' }} />
              <th>Avg Levels</th>
              <th style={{ width: '70px' }} />
              <th style={{ width: '50px' }}>Sync</th>
              <th style={{ width: '70px' }}>Offset</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(userCount).keys()).map(index =>
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                <td style={{ transform: 'translateY(2px)' }}>
                  <svg width='25' height='16' viewBox='0 0 10 10'>
                    <circle cx='5' cy='5' r='5' fill={isOnline[index] ? '#0F0' : '#F00'} />
                  </svg>
                </td>
                <td>
                  <input type='range' min={0} max={1.5} step={0.05} value={gainLevels[index]} onMouseUp={event => onGainMouseUp(index, +event.target.value)} onChange={event => onGainChange(index, +event.target.value)} style={{ transform: 'translateY(2px)' }} />
                </td>
                <td>{gainLevels[index].toFixed(2)}</td>
                <td>
                  <input type='range' min={-1} max={1} step={0.05} value={panLevels[index]} onMouseUp={event => onPanMouseUp(index, +event.target.value)} onChange={event => onPanChange(index, +event.target.value)} style={{ transform: 'translateY(1px)' }} />
                </td>
                <td>{panLevels[index].toFixed(2)}</td>
                <td><meter min={0} max={1} high={0.9} value={Math.pow(avgPower[index] / 100 + 1, 2)} style={{ width: '110px' }} /></td>
                <td>{`${avgPower[index].toFixed(0)} dB`}</td>
                <td style={{ transform: 'translateY(2px)' }}>
                  <svg width='50' height='16' viewBox='0 0 10 10'>
                    <circle cx='5' cy='5' r='5' fill={!incomingConnectionRef.current ? '#F00' : delayResolved[index] ? '#0F0' : '#EE0'} />
                  </svg>
                </td>
                <td style={{ textAlign: 'center' }}>{`${syncOffset[index].toFixed(0)} ms`}</td>
              </tr>
            )}
            <tr>
              <td style={{ textAlign: 'center' }}>C</td>
              <td />
              <td>
                <input type='range' min={0} max={1.5} step={0.05} value={gainLevels[userCount + 1]} onChange={onClickGainChange} style={{ transform: 'translateY(2px)' }} />
              </td>
              <td>{gainLevels[userCount + 1].toFixed(2)}</td>
              <td>
                <input type='range' min={-1} max={1} step={0.05} value={panLevels[userCount + 1]} onChange={onClickPanChange} style={{ transform: 'translateY(1px)' }} />
              </td>
              <td>{panLevels[userCount + 1].toFixed(2)}</td>
              <td><meter min={0} max={1} high={0.9} value={Math.pow(avgPower[userCount + 1] / 100 + 1, 2)} style={{ width: '110px' }} /></td>
              <td>{`${avgPower[userCount + 1].toFixed(0)} dB`}</td>
              <td style={{ transform: 'translateY(2px)' }}>
                <svg width='50' height='16' viewBox='0 0 10 10'>
                  <circle cx='5' cy='5' r='5' fill={!incomingConnectionRef.current ? '#F00' : delayResolved[userCount] ? '#0F0' : '#EE0'} />
                </svg>
              </td>
              <td style={{ textAlign: 'center' }}>{`${syncOffset[userCount].toFixed(0)} ms`}</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'center' }}>M</td>
              <td />
              <td>
                <input type='range' min={0} max={1.5} step={0.05} value={gainLevels[userCount]} onChange={onMasterGainChange} style={{ transform: 'translateY(2px)' }} />
              </td>
              <td>{gainLevels[userCount].toFixed(2)}</td>
              <td />
              <td />
              <td><meter min={0} max={1} high={0.9} value={Math.pow(avgPower[userCount] / 100 + 1, 2)} style={{ width: '110px' }} /></td>
              <td>{`${avgPower[userCount].toFixed(0)} dB`}</td>
            </tr>
          </tbody>
        </table>
        <table style={{ marginTop: '10px' }}>
          <tbody>
            <tr>
              <td>Reverb strength: </td>
              <td><input type='range' value={reverbStrength} min={0} max={1} step={0.05} onChange={onSetReverbStrength} /></td>
              <td>{reverbStrength.toFixed(2)}</td>
              <td style={{ paddingLeft: '40px' }}>Chat:</td>
              <td><input type='checkbox' checked={chatEnabled} onChange={onChatEnabledChange} /></td>
              <td style={{ paddingLeft: '40px' }}>Tune:</td>
              <td><input type='checkbox' checked={tuningEnabled} onChange={onTuningEnabledChange} /></td>
              <td style={{ paddingLeft: '40px' }}><button type='button' onClick={onRecordClick}>{isRecording ? 'Stop Recording' : 'Record'}</button></td>
              <td style={{ paddingLeft: '40px' }}><button type='button' onClick={onStreamClick}>{isStreaming ? 'Stop Streaming' : 'Stream'}</button></td>
            </tr>
          </tbody>
        </table>
        <table style={{ marginTop: '10px', marginBottom: '20px' }}>
          <tbody>
            <tr>
              <td>Click track:</td>
              <td style={{ paddingLeft: '10px' }}><input type='file' accept='audio/*' onChange={onClickTrackFileChange} /></td>
              <td><button type='button' disabled={!isClickTrackLoaded} onClick={onPlayClickTrack}>{isClickTrackPlaying ? 'Stop' : 'Play'}</button></td>
              <td style={{ paddingLeft: '40px' }}>Bandwidth:</td>
              <td>
                <select value={bandwidth} onChange={onSetBandwidth}>
                  <option value='unlimited'>unlimited</option>
                  <option value='2000'>2000</option>
                  <option value='1000'>1000</option>
                  <option value='500'>500</option>
                  <option value='250'>250</option>
                  <option value='125'>125</option>
                  <option value='75'>75</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default HostApp
